<template>
  <div class="survey">
    <div class="top"></div>
    <div class="content">
      <load-list :interFace="interFace">
        <template v-slot:list="props">
          <div
            class="con_t"
            @click="toDetail(item.id)"
            v-for="(item, index) in props.list"
            :key="index"
          >
            <div class="con_t1">
              <img src="@/assets/images/survey/1.png" alt="" />
              {{ item.title }}
            </div>
            <div class="con_t2">
              <img src="@/assets/images/survey/2.png" alt="" />
            </div>
          </div>
        </template>
      </load-list>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import LoadList from "@/components/SearchLoadList";
import { getEventLists } from "../api";
export default {
  components: {
    LoadList,
  },
  data() {
    return {
      interFace: {
        api: getEventLists,
        params: {},
      },
    };
  },
  created() {
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        name: "electorateDetail",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.survey {
  width: 100%;
  position: relative;
  .top {
    height: 4.2rem;
    widows: 100%;
    background: url("../../../assets/images/survey/top_bg.png");
  }
  .content {
    width: 100%;
    min-height: 12.5rem;
    padding: 0.3rem;
    color: #333333;
    font-size: 0.3rem;
    font-weight: 700;
    background: #ffffff;
    position: absolute;
    left: 0;
    top: 2rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    .con_t {
      height: 42px;
      display: flex;
      font-weight: 100;
      justify-content: space-between;
      color: #3b93ff;
      background: #fafafa;
      border-radius: 5px;
      align-items: center;
      margin: 0.3rem 0;
      font-weight: 500;
      .con_t1 {
        display: inline-block;
        img {
          padding: 0 0.2rem;
          vertical-align: middle;
        }
      }
      .con_t2 {
        img {
          padding: 0 0.2rem;
        }
      }
    }
  }
}
</style>